export const global = {
	label: 'block text-sm font-medium text-gray-700 mb-1',
	outer: 'group w-full',
	input: 'appearance-none',
	inner: 'overflow-hidden w-full',
	help: 'block mt-2 text-blue-500 text-xs',
	message: 'block mt-2 text-red-500 text-xs',
	prefixIcon: 'w-10 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-gray-400 bg-white',
	suffixIcon: 'w-7 pr-3 flex self-stretch grow-0 shrink-0',
};
