export const SpecialUser = {
	PatrickLanz: 2,
	AlbertoRigamonti: 3,
	LegalDepartment: 44,
	AliseKessler: 89,
	RahelehAhadpour: 93,
	UlianaMalysheva: 96,
	RomainLanz: 17139,
	CEVBeach: 17314,
	YuCao: 22646,
	LarryCarrel: 30225,
	AlessandraDeliberato: 44823,
	MehdiMhidi: 46936,
	AdrianaTatasel: 48235,
	BrendanChiang: 51926,
	ShalinyKrishnan: 52558,
	CharlotteBourquin: 63756,
	LucasValle: 75826,
	MeganeBianco: 75830,
	JulienRipouteau: 87801,
	TestDevFederation: 37,
	TestDevFrFederation: 21345,
} as const;
