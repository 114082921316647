import { queryKeyFactory } from '~/app/auth/queries/query-key-factory';
import { queryClient } from '~/infrastructure/query-client';
import { assert } from '~/utils/assert';
import type { User } from '~/app/auth/schemas/user';

export function useAuth() {
	const currentUser = queryClient.getQueryData<User>(queryKeyFactory.currentUser());

	if (!currentUser) {
		// Force refresh to display the login form again.
		window.location.reload();

		throw new Error('Page should have been reloaded');
	}

	assert(currentUser, 'User is not authenticated');

	return currentUser;
}
